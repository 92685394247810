import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Clients from '../pages/Clients';
import OurValues from '../pages/OurValues';
import NotFound from '../pages/NotFound';
import PasswordPage from '../pages/PasswordPage';

const AppRoutes = ({ isAuthenticated, onAuthenticate }) => (
  <Routes>
    {!isAuthenticated ? (
      <Route path="*" element={<PasswordPage onAuthenticate={onAuthenticate} />} />
    ) : (
      <>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/values" element={<OurValues />} />
        <Route path="*" element={<NotFound />} />
      </>
    )}
  </Routes>
);

export default AppRoutes;
