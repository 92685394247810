import React from 'react';
import '../assets/styles/OurValues.css';

const values = [
  { title: "Integrity", description: "Upholding the highest standards of professionalism and ethical practice in all our endeavors." },
  { title: "Innovation", description: "Pioneering solutions by staying at the forefront of clinical research and technological advancements." },
  { title: "Collaboration", description: "Fostering strong partnerships with our clients and stakeholders to achieve shared goals." },
  { title: "Excellence", description: "Committing to the highest quality of service and expertise in every project." },
  { title: "Adaptability", description: "Embracing change and complexity to deliver tailor-made strategies that meet the evolving needs of the healthcare industry." },
];

const OurValues = () => (
  <div className="our-values-container">
    <h1>Our Values</h1>
    <div className="values-grid">
      {values.map((value, index) => (
        <div key={index} className="value-card">
          <h3>{value.title}</h3>
          <p>{value.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default OurValues;
