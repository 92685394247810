import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes/Routes';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check sessionStorage for authentication flag
    if (sessionStorage.getItem('authenticated') === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('authenticated', 'true');
  };

  return (
    <Router>
      {isAuthenticated && <Header />}
      <Routes isAuthenticated={isAuthenticated} onAuthenticate={handleAuthentication} />
      {isAuthenticated && <Footer />}
    </Router>
  );
};

export default App;
