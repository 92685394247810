import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import '../assets/styles/Header.css';
import logo from '../assets/images/logo.png';

const Header = () => (
  <header className="header">
    <div className="logo-container">
      <Link to="/">
        <img src={logo} alt="Company Logo" className="logo" />
      </Link>
    </div>
    <nav className="nav-links">
      <Link to="/" className="nav-link">Home</Link>
      <Link to="/about" className="nav-link">About Us</Link>
      <Link to="/clients" className="nav-link">Our Services</Link>
      <Link to="/values" className="nav-link">Our Values</Link>
    </nav>
    <Button text="Get Started" link="https://calendly.com/ainsleymaclean/30min" />
  </header>
);

export default Header;
