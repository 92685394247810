import React from 'react';
import UseCaseCard from '../components/UseCaseCard';
import Testimonial from '../components/Testimonial';
import Button from '../components/Button';
import backgroundPlaceholder from '../assets/images/background-placeholder.png';
import '../assets/styles/Clients.css';

const useCases = [
  {
    category: "Executive Coaching",
    title: "Leadership Development",
    description: "Mentorship for healthcare professionals transitioning to executive roles.",
    image: backgroundPlaceholder,
  },
  {
    category: "Private Equity Firms",
    title: "Healthcare Advising",
    description: "Guidance on a wide range of healthcare decisions across specialties.",
    image: backgroundPlaceholder,
  },
  {
    category: "Medical AI Startups",
    title: "Technology Integration",
    description: "Coaching on scaling AI-based diagnostic and therapeutic tools.",
    image: backgroundPlaceholder,
  },
  {
    category: "Medical Device Manufacturers",
    title: "Regulatory Guidance",
    description: "Support in embedding AI, clinical validation, and FDA compliance.",
    image: backgroundPlaceholder,
  },
  {
    category: "Health Systems",
    title: "Technology Implementation",
    description: "Advice on evaluating and implementing new healthcare technologies.",
    image: backgroundPlaceholder,
  },
  {
    category: "Consumer Health Companies",
    title: "AI for Wellness",
    description: "Guidance on AI for health monitoring, wearables, and regulatory compliance.",
    image: backgroundPlaceholder,
  },
  {
    category: "Government Agencies",
    title: "Regulation Design",
    description: "Consultation on healthcare and AI policy with expert insights.",
    image: backgroundPlaceholder,
  },
  {
    category: "Pharmaceutical Companies",
    title: "AI for Drug Discovery",
    description: "Leverage AI for drug development and optimize clinical trials.",
    image: backgroundPlaceholder,
  },
  {
    category: "Venture Capital Firms",
    title: "Investment Advising",
    description: "Strategic input on investments in practical, scalable solutions.",
    image: backgroundPlaceholder,
  },
  {
    category: "Health Insurance Companies",
    title: "AI Evaluation",
    description: "Assistance in selecting AI tools to improve efficiency and patient care.",
    image: backgroundPlaceholder,
  },
  {
    category: "Clinicians and Providers",
    title: "Clinical AI Adoption",
    description: "Education on integrating AI into workflows for better patient outcomes.",
    image: backgroundPlaceholder,
  },
  {
    category: "Academic Institutions",
    title: "AI in Research",
    description: "Support for AI applications in healthcare research and grant applications.",
    image: backgroundPlaceholder,
  },
  {
    category: "Telemedicine Companies",
    title: "Virtual Care Optimization",
    description: "Integration of AI for remote diagnostics and secure patient data handling.",
    image: backgroundPlaceholder,
  }
];

const Clients = () => (
  <div className="clients-container">
    <h1>Our Services</h1>
    
    <div className="use-cases-grid">
      {useCases.map((useCase, index) => (
        <UseCaseCard 
          key={index}
          category={useCase.category}
          title={useCase.title}
          description={useCase.description}
          image={useCase.image}
        />
      ))}
    </div>
    
    <Button text="Get Started" link="https://calendly.com/ainsleymaclean/30min" />
    
  </div>
);

export default Clients;
