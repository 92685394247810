import React, { useState } from 'react';

const PasswordPage = ({ onAuthenticate }) => {
  const [password, setPassword] = useState('');
  const correctPassword = "h3althcareAIcapital!&$"; // Directly storing the password here

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if (password === correctPassword) {
      sessionStorage.setItem('authenticated', 'true');
      onAuthenticate();
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', background: 'gray' }}>
      <form onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter Password"
          style={{ padding: '10px', fontSize: '16px' }}
        />
        <button type="submit" style={{ marginLeft: '10px', padding: '10px', fontSize: '16px' }}>Submit</button>
      </form>
    </div>
  );
};

export default PasswordPage;
