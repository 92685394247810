import React from 'react';
import '../assets/styles/UseCaseCard.css';

const UseCaseCard = ({ category, title, description, image }) => (
  <div className="use-case-card">
    <div className="use-case-image" style={{ backgroundImage: `url(${image})` }}></div>
    <div className="use-case-content">
      <p className="use-case-category">{category}</p>
      <h3 className="use-case-title">{title} <span className="hover-arrow">➔</span></h3>
      <p className="use-case-description">{description}</p>
    </div>
  </div>
);

export default UseCaseCard;
